<template>
  <b-card-code no-body :title="`Completed mental health checks: ${subcategory}`">
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >

          <!-- page length -->
          <b-form-group
            label="Show"
            label-cols="5"
            label-align="left"
            label-size="sm"
            label-for="sortBySelect"
            class="text-nowrap mb-md-0 mr-1"
          >
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              inline
              :options="pageOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </div>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="data"
      :fields="fields"
      sort-by.sync="score"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      ref="table"
    >
      <!-- Column: Attempts -->
      <template #cell(attempts)="data">
        <router-link :to="{name: 'questionnaire-details', params: { id: data.item.id } }">
          <span class="font-weight-bold d-block text-nowrap">
            <span class="text-nowrap">View details</span>
          </span>
        </router-link>
      </template>

      <!-- Column: Score -->
      <template #cell(score)="data">
        <div class="text-nowrap">
          <b-badge
          pill
          class="text-capitalize"
        >
        <span>
          {{ data.item.score['Questionnaire Score'].section_score }}
        </span>
        </b-badge>
        </div>
      </template>

      <!-- Column: Reactions -->
      <template #cell(reactions)="data">
        <div class="text-nowrap">
          <reactions readOnly="true" :data="data.item" withSpaces="true" />
        </div>
      </template>

      <!-- Column: Next Run -->
      <template #cell(submission_date)="data">
        <span class="align-text-top text-capitalize">
          {{ data.item.created_at }}
        </span>
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-card-body>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BRow,
  BCol,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BCardBody,
} from "bootstrap-vue";
import Reactions from "@/components/users/Reactions.vue"

export default {
  components: {
    BRow,
    BCol,
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
    Reactions,
  },
  props: {
    data: {
      type: Array
    },
    subcategory: {
      type: String
    }
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortDesc: false,
      sortDirection: "asc",
      fields: [
        { key: "attempts", label: "History" },
        { key: "score", label: "Trait scores", class: "text-center", sortable: true },
        { key: "reactions", label: "Reactions" },
        { key: "submission_date", label: "Completed on", class: "text-center text-nowrap", sortable: true }
      ],
    };
  },
  created() {
    // Set the initial number of items
    this.totalRows = this.data.length;
  },
  watch:{
    data() {
      this.totalRows = this.data.length
    },
  }
};
</script>

<style scoped>
  .card-header {
    display: none;
  }
</style>