<template>
  <b-card>
    <b-card-title class="text-center">
      Your community
      <h6 class="mt-1">
        <what-does-this-mean type="users-same-trait" />
      </h6>
    </b-card-title>

    <div v-if="data.length > 0">
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        style="height: 640px"
        suppressScrollX="false"
      >
        <app-timeline :key="timelineKey" class="mt-1">
          <app-timeline-item
            variant="primary"
            v-for="(trait, index) in data"
            :key="index"
            :color="trait['Questionnaire Score'].section_score_color"
            class="timeline-item"
          >
            <users-same-trait-single :trait="trait" />
            <hr />
          </app-timeline-item>
        </app-timeline>
      </vue-perfect-scrollbar>
      <div v-if="nextPage != 1" class="text-center">
        <b-button
          size="sm"
          variant="outline-primary"
          pill
          class="mt-1"
          @click="getUserSameTraitData(nextPage)"
          :disabled="isDisabled"
          >
            Load older results
            <b-spinner v-if="isDisabled" label="Loading..." :small="true"/>
        </b-button>
      </div>
    </div>
    <div v-else class="text-center">
      <span class="mhc-link-underline" @click="inviteFriends()">
        Start building your community by inviting friends and family.
      </span>
    </div>
    <div class="mt-auto">
        <b-button
          @click="callOpenModal"
          variant="primary"
          block
          class=""
          >Grow your community</b-button
        >
    </div>
  </b-card>
</template>

<script>
import EventBus from "../../main.js";
import BCardCode from "@core/components/b-card-code";
import {
  BCard,
  BCardTitle,
  BAvatar,
  BMedia,
  BButton,
  BCollapse,
  VBToggle,
  BListGroup,
  BListGroupItem,
  BAvatarGroup,
  BBadge,
  VBTooltip,
  BSpinner,
} from "bootstrap-vue";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import Ripple from "vue-ripple-directive";
import FollowButton from "@/components/users/FollowButton.vue";
import Reactions from "@/components/users/Reactions.vue";
import UsersSameTraitSingle from "@/components/my-hub/UsersSameTraitSingle.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import WhatDoesThisMean from "../../components/modals/WhatDoesThisMean.vue";

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
    BCardCode,
    BCard,
    BCardTitle,
    BMedia,
    BAvatar,
    BButton,
    BCollapse,
    BListGroup,
    BListGroupItem,
    BAvatarGroup,
    BBadge,
    BSpinner,
    FollowButton,
    Reactions,
    UsersSameTraitSingle,
    VuePerfectScrollbar,
    WhatDoesThisMean,
  },
  directives: { "b-toggle": VBToggle, "b-tooltip": VBTooltip, Ripple },
  props: ["questionnaireId", "subcategory"],
  setup() {
    // Setup perfect scrollbar
    const perfectScrollbarSettings = {
      wheelPropagation: true,
      suppressScrollX: false,
      useBothWheelAxes: false,
    };
    return {
      perfectScrollbarSettings,
    };
  },
  data() {
    return {
      data: [],
      timelineKey: 0,
      nextPage: 1,
      isDisabled: false,
    };
  },
  methods: {
    callOpenModal() {
      document.getElementById("openModalId").click();
    },
    openFinishedTraitModal(id) {
      EventBus.$emit("openFinishedTraitSupportModal", id);
    },
    /**
     * pageNumber - the page number that needs to be loaded
     *
     * loadPrevious - a flag that is used to determine if we should fetch all previously loaded results.
     * This is used when a user lazy loads for example to page 3, and then clicks follow or unfollow on some user result.
     * In that case we need to reaload all of the results in order to update user follow button
     * if it was 30 results, then we reaload all 30 of them and then continue to lazy load another 5 by 5 or however many
     * until there are no more results to load
     *
     * V.R 18.05.2021.
     *
     */
    getUserSameTraitData(pageNumber = 1, loadPrevious = false) {
      this.isDisabled = true;

      let vm = this;

      let urlAddon = "";
      if (loadPrevious == false) {
        urlAddon = "?page=" + pageNumber;
      } else {
        urlAddon = "?total=" + this.data.length;
      }

      this.$http
        .get(
          "/api/auth/questionnaire/results/related/" +
            this.questionnaireId +
            urlAddon
        )
        .then((res) => {
          if(res != undefined) {
            this.isDisabled = false;

            if (this.nextPage == 1 || loadPrevious == true) {
              this.data = res.data.data;
            } else {
              res.data.data.forEach((element) => {
                this.data.push(element);
              });
            }

            vm.timelineKey++;

            if (res.data.next_page_url != null && loadPrevious == false) {
              this.nextPage++;
            } else {
              if (loadPrevious == false) {
                this.nextPage = 1;
              }
            }
          }
        })
        .catch((err) => {
          console.log(err);
          this.isDisabled = false;
          // Show toast
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },

    // Open modal invite friends
    inviteFriends() {
      EventBus.$emit("inviteFriends");
    },
  },
  mounted() {
    var self = this;
    // Get fresh user same trait data on changed follow status
    EventBus.$on("followStatusChanged", () => {
      self.getUserSameTraitData(0, true);
    });

    this.getUserSameTraitData();
  },
};
</script>

<style scoped>
.app-timeline {
  margin-right: 1rem;
}

.timeline-item {
  padding-left: 1.5rem;
}

.timeline-item:not(:last-of-type) {
  padding-bottom: 0rem;
}
</style>