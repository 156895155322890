<template>
  <b-card v-if="data" no-body class="card-developer-meetup">
    <b-card-body>
      <div
        class="meetup-header d-flex align-items-center justify-content-center"
      >
        <div class="my-auto">
          <b-card-title class="mb-25">
            {{ title }}
          </b-card-title>
        </div>
      </div>

      <!-- media -->
      <vue-perfect-scrollbar
        :settings="perfectScrollbarSettings"
        class="scrollable-container media-list scroll-area"
        :class="upcomingCheckHeight"
        suppressScrollX="false"
      >
        <b-media
          v-for="(reminder, index) in data.reminderData"
          :key="index"
          no-body
        >
          <b-media-aside class="mr-1">
            <feather-icon icon="CalendarIcon" size="20" />
          </b-media-aside>
          <b-media-body>
            <h6 class="mb-0">
              {{ reminder.name }}
            </h6>
            <small>{{ reminder.created_at }}</small>
          </b-media-body>
        </b-media>
      </vue-perfect-scrollbar>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BButton,
  BCardBody,
  BCardTitle,
  BMedia,
  BMediaAside,
  BMediaBody,
} from "bootstrap-vue";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  components: {
    BCard,
    BButton,
    BCardBody,
    BCardTitle,
    BMedia,
    BMediaAside,
    BMediaBody,
    VuePerfectScrollbar,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
    },
  },
  setup() {
    // Setup perfect scrollbar
    const perfectScrollbarSettings = {
      wheelPropagation: false,
      suppressScrollX: false,
      useBothWheelAxes: false,
    };
    return {
      perfectScrollbarSettings,
    };
  },
  data() {
    return {
      scrollHeight: "height370",
    };
  },
  computed: {
    upcomingCheckHeight() {
      if (this.$route.fullPath.includes("questionnaire-submissions")) {
        this.scrollHeight = "height150";
      } else {
        this.scrollHeight = "height370";
      }
      return this.scrollHeight;
    },
  },
};
</script>

<style scoped>
.height370 {
  height: 370px;
}
.height150 {
  height: 150px;
}

.media-list .media {
  padding: 0;
}
</style>
