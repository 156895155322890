<template>
  <b-card no-body>
    <b-card-header>
      <!-- title, date and subtitle -->
      <div class="w-100">
        <b-card-title class="mb-1 w-100 line-chart-title-date">
          <div>
            {{ title }}
          </div>
        </b-card-title>
        <b-card-sub-title><what-does-this-mean :type="type" :subcategory="subcategory" /></b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

    </b-card-header>
    
    <b-card-body>
      <div v-if="data.series[0].data.length > 0">
        <vue-apex-charts
          v-if="data.chartOptions.xaxis.categories.length > 0"
          ref="lineChart"
          type="line"
          height="400"
          class="m-auto w-100"
          :options="data.chartOptions"
          :series="data.series"
        />
      </div>
      <div v-else class="d-flex justify-content-center align-items-center">
        <p class="mb-0 py-3 text-center">No mental health check results data was found for the selected period</p>
      </div>
    </b-card-body>

  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BBadge, BFormSelect
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'

import WhatDoesThisMean from '../../components/modals/WhatDoesThisMean.vue'

export default {
  components: {
    VueApexCharts,
    flatPickr,
    WhatDoesThisMean,
    BCardHeader,
    BCard,
    BBadge,
    BFormSelect,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
    title: {
      default: "Your overall mental health trait score",
    },
    type: {
      type: String
    },
    subcategory: {
      type: String,
      default: ""
    }
  },
  watch: { 
   	$props: {
      handler(value) {
        if(this.$refs.lineChart != undefined) {
          this.$refs.lineChart.updateSeries([{
            data: value.data.series[0].data
          }])

          this.$refs.lineChart.updateOptions([{
            xaxis: {
              categories: value.data.chartOptions.xaxis.categories
            }
          }])
        }
      },
      deep: true,
      immediate: true,
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
