<template>
  <section class="pb-4">
    <b-row class="match-height">
      <b-col lg="4" xl="4">
        <b-overlay :show="show" rounded="sm" :class="shouldDisplayFixedClass()">
          <average-score-circle-chart 
            :questionnaireCategory="questionnaireCategory" 
            :data="data.circleChart" 
            :chartColor="cColor" 
            :bar="data.barChart" 
            :barColors="bColors" 
            :barCategories="barCategories"
            :title="`Your ${subcategory} ${scoreType}`" 
            :type="whatIsThisType" 
            :subcategory="subcategory" 
            :message="data.circleChart.series[1]" 
            :qName="subcategory" 
            :completedOn="questionnairesAttempts" />
        </b-overlay>
      </b-col>

      <!-- Users Finished Same Trait 1/3-->
      <b-col lg="8" xl="4">
        <b-overlay :show="show" rounded="sm" class="fix-height-for-cols">
          <users-same-trait :questionnaireId="questionnaireTookId" :subcategory="subcategory" />
        </b-overlay>
      </b-col>

      <b-modal
        id="frequency-modal"
        ref="frequency-modal"
        :title="`Upcoming ${subcategory} checks`"
        centered
        ok-only
        hide-footer
      >
        <b-form-select
          v-model="frequency"
          :options="select_options"
        ></b-form-select>
        <div class="d-flex justify-content-end">
          <b-button
            class="mt-1"
            type="submit"
            variant="primary"
            @click="saveReminderFrequency()"
          >
            Save
          </b-button>
        </div>
      </b-modal>

      <b-col lg="12" xl="4">
        <b-overlay :show="show" rounded="sm">
          <range-datepicker :from="from_period" :to="to_period" />
        </b-overlay>

        <!-- Average Line chart -->
        <b-row class="match-height">
          <b-col lg="12">
            <b-overlay :show="show" rounded="sm" class="fix-height-for-cols">
              <average-score-line-chart :data="data.lineChartSimple" :title="`${subcategory} progress chart`" type="questionnaire-line-chart" :subcategory="subcategory"/>
            </b-overlay>
          </b-col>
        </b-row>

        <b-row class="match-height">
          <b-col lg="12">
            <b-overlay :show="show" rounded="sm">
              <b-button block class="bg-mhc-dark mb-1" @click="showModal">
                Change Reminder Frequency
              </b-button>
              <future-reminders :data="data.reminders" :title="`Upcoming ${subcategory} checks`" />
            </b-overlay>
          </b-col>
        </b-row>
      </b-col>

    </b-row>

    <b-row class="match-height">
      <b-col cols="12">
        <b-overlay :show="tableShowOverlay" rounded="sm">
          <questionnaire-attempts-list :data="questionnairesAttempts" :subcategory="subcategory" />
        </b-overlay>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import store from "@/store/index";
import EventBus from "@/main";
import { BRow, BCol, BOverlay, BButton, BModal, BFormSelect } from "bootstrap-vue";
import AverageScoreCircleChart from "../../components/my-hub/AverageScoreCircleChart.vue";
import UsersSameTrait from "../../components/my-hub/UsersSameTrait.vue"
import FutureReminders from "../../components/my-hub/FutureReminders.vue";
import AverageScoreLineChart from "../../components/my-hub/AverageScoreLineChart.vue";
import QuestionnaireAttemptsList from "../../components/my-hub/QuestionnaireAttemptsList.vue";
import RangeDatepicker from "../../components/my-hub/RangeDatepicker.vue";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    BOverlay,
    BButton,
    BModal,
    BFormSelect,
    // Our components
    AverageScoreCircleChart,
    UsersSameTrait,
    FutureReminders,
    AverageScoreLineChart,
    QuestionnaireAttemptsList,
    RangeDatepicker,
  },
  computed: {
    show() {
      return store.state.formStoreModule.show;
    },
  },
  data() {
    return {     
      bColors: [],
      scoreType: "",
      cColor: "",
      barCategories: [],
      questionnaireCategory: "",
      questionnaireId: null,
      data: {
        barChart: [],
        circleChart: {
          series: [20, 80],
        },
        lineChartSimple: {
          series: [
            {
              data: [],
            },
          ],
          chartOptions: {
            chart: {
              zoom: {
                enabled: false,
              },
              toolbar: {
                show: false,
              },
            },
            markers: {
              strokeWidth: 7,
              strokeOpacity: 1,
              strokeColors: ["#59949c"],
              colors: ["#59949c"],
            },
            colors: ["#59949c"],
            dataLabels: {
              enabled: false,
            },
            stroke: {
              curve: "straight",
            },
            grid: {
              xaxis: {
                lines: {
                  show: true,
                },
              },
            },
            tooltip: {
              custom(data) {
                return `${'<div class="px-1 py-50"><span>'}${
                  data.series[data.seriesIndex][data.dataPointIndex]
                }</span></div>`;
              },
            },
            xaxis: {
              categories: [],
            },
            yaxis: {
              min: 0,
              max: 10,
              tickAmount: 10,
              labels: {
                formatter: (value) => { return parseInt(value) },
              },
              title: {
                text: "Score out of 10"
              }
            },
          },
        },
        reminders: {
          reminderData: [],
        },
      },
      from_period: "",
      to_period: "",

      tableShowOverlay: true,
      select_options: [
        { value: "weekly", text: "Weekly" },
        { value: "monthly", text: "Monthly" },
        { value: "quarterly", text: "Quarterly" },
        { value: "never", text: "Never"}
      ],
      frequency: "",
      questionnairesAttempts: [],
      searchTerm: "",
      questionnaireTookId: this.$route.params.id,
      category: "", //category
      subcategory: "", //type,
      whatIsThisType: ""
    };
  },
  methods: {
    shouldDisplayFixedClass() {
      if(this.questionnaireCategory == "'My mental health and my life situation(s)" || this.questionnaireCategory == "My mental health and my job/career") {
        //return "fix-height-for-cols";
      }
      return  ""
    },
    showModal() {
      if(this.$refs['frequency-modal'] != undefined) {
        this.$refs['frequency-modal'].show()
      }
    },
    hideModal() {
      this.$refs['frequency-modal'].hide()
    },
    saveReminderFrequency() {
      // Request to update reminder
      this.$http.patch("/api/auth/questionnaire/reminder", {
          "reminder": this.frequency,
          "user_questionnaire_took_id": this.questionnaireTookId
        })
        .then((res) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title:
              "Successfully updated reminder frequency!",
                icon: "CheckSquareIcon",
                variant: "success",
            },
          });

          // Hide frequency modal
          this.$refs['frequency-modal'].hide()
          // Return analytics data
          this.getData();
        })
        .catch((error) => {
          console.log(error)
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: error.response.data.message,
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },

    getData(from = "", to = "") {
      store.commit("formStoreModule/setShow", true);
      
      let apiToCall = `/api/auth/trait/analytics?questionnaire_took_id=${this.questionnaireTookId}&from=${from}&to=${to}`;
      if(this.questionnaireCategory == "My mental health and how I feel") {
        apiToCall = `/api/auth/feelings/analytics?questionnaire=${this.questionnaireId}&from=${from}&to=${to}`
      } else if(this.questionnaireCategory == "My mental health and my job/career") {
        apiToCall = `/api/auth/career/analytics?questionnaire=${this.questionnaireId}&from=${from}&to=${to}`
      } else if(this.questionnaireCategory == "My mental wellbeing") {
        apiToCall = `/api/auth/wellbeing/analytics?questionnaire=${this.questionnaireId}&from=${from}&to=${to}`
      }

      // Return analytics data
      this.$http.get(apiToCall)
      .then((res) => {
        
        if(res != undefined) {
          // Fill Average score

          let score = 0;
          let yearlyData = null;
          
          if(this.questionnaireCategory == "My mental health and my life situation(s)") {       
            yearlyData = res.data.scores_by_year;
            score = res.data.average_score.score;
            this.cColor = res.data.average_score.color;
            this.whatIsThisType = "questionnaire-circle-chart"
            this.scoreType = "Trait Score";
          } else if(this.questionnaireCategory == "My mental health and my job/career") {
            yearlyData = res.data.career_index_line_chart_data;
            score = res.data.career_index_data[0].average_score_for_feeling;
            this.cColor = res.data.career_index_data[0].score_color;
            this.scoreType = "Trait Score";
            this.whatIsThisType = "questionnaire-circle-chart"
          } else if(this.questionnaireCategory == "My mental wellbeing") {
            yearlyData = res.data.wellbeing_index_line_chart_data;
            score = res.data.wellbeing_index_data[0].average_score_for_feeling;
            this.cColor = res.data.wellbeing_index_data[0].score_color;
            this.scoreType = "Trait Score";
            this.whatIsThisType = "wellbeing-score-explanation"
          } else {
            yearlyData = res.data.feelings_index_line_chart_data;
            score = res.data.feelings_index_data[0].average_score_for_feeling;
            this.cColor = res.data.feelings_index_data[0].score_color;
            this.scoreType = "Index score";
            this.whatIsThisType = "emotional-score-explanation"
          }

          this.data.circleChart.series = [
            score,
            10 - score,
          ].reverse();

          if(score > 9.7) {
            this.data.circleChart.series = [10, 0.3].reverse();
          }

          if(score == 0) {
            // Problem with compontent when value is 0
            score = 0;
            //this.data.circleChart.series = [10, 0.3];
            this.data.circleChart.series = [];
          }

          // Fill LineChart values and options
          let lineChartValues = [];
          let lineChartOptions = [];
          
          for(let y = 0; y < Object.keys(yearlyData).length; y++) {
            let year = Object.keys(yearlyData)[y]

            for(let m=0; m<Object.keys(yearlyData[year]).length; m++) {
              let month = Object.keys(yearlyData[year])[m]

              let axisName = year +'/'+ month

              lineChartOptions.push(axisName)

              lineChartValues.push(yearlyData[year][month].avg_score);
            } 
          }

          this.data.lineChartSimple.chartOptions.xaxis.categories = lineChartOptions;
          this.data.lineChartSimple.series[0].data = lineChartValues;
          this.from_period = res.data.from_date
          this.to_period = res.data.to_date

          // Fill BarChart values
          if(this.questionnaireCategory == "My mental health and my life situation(s)") {            
            let biological = res.data.average_score_bio
            let psychological = res.data.average_score_psycho
            let social = res.data.average_score_social
            this.data.barChart = [biological.score, psychological.score, social.score]
            this.bColors = [biological.color, psychological.color, social.color]
            this.barCategories = ["Biological", "Psychological", "Social"]
          } else if(this.questionnaireCategory == "My mental health and my job/career") {
            let before_work = res.data.career_index_data[0].section_scores['Before Work']
            let at_work = res.data.career_index_data[0].section_scores['At Work']
            let after_work = res.data.career_index_data[0].section_scores['After Work']
            this.data.barChart = [before_work.section_score, at_work.section_score, after_work.section_score]
            this.bColors = [before_work.section_color, at_work.section_color, after_work.section_color]
            this.barCategories = ["Before Work", "At Work", "After Work"]
          } else if(this.questionnaireCategory == "My mental wellbeing") {
            let calm = res.data.wellbeing_index_data[0].section_scores['Calm & Inner peace']
            let joy = res.data.wellbeing_index_data[0].section_scores['Joy and Contentment']
            let adaptability = res.data.wellbeing_index_data[0].section_scores['Adaptability']
            let rest = res.data.wellbeing_index_data[0].section_scores['Rest & Relaxation']
            let health = res.data.wellbeing_index_data[0].section_scores['Health & Lifestyle']
            let relationships = res.data.wellbeing_index_data[0].section_scores['Relationships & Connections']
            let satisfaction = res.data.wellbeing_index_data[0].section_scores['Satisfaction & Fulfillment']
            
            this.data.barChart = [calm.section_score, joy.section_score, adaptability.section_score, rest.section_score, health.section_score, relationships.section_score, satisfaction.section_score]
            this.bColors = [calm.section_color, joy.section_color, adaptability.section_color, rest.section_color, health.section_color, relationships.section_color, satisfaction.section_color]
            this.barCategories = ['Calm & Inner peace','Joy and Contentment', 'Adaptability', 'Rest & Relaxation', 'Health & Lifestyle', 'Relationships & Connections', 'Satisfaction & Fulfillment']
          }
        }
      })
      .then((res) => {
        store.commit("formStoreModule/setShow", false);
      })
      .catch((error) => {
        store.commit("formStoreModule/setShow", false);
        console.log(error);
        
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Something went wrong. Please try again",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      });
    },

    getSubmissionsData() {
      //Return questionnaires list
      this.$http.get(
          "/api/auth/user/questionnaires/" + this.questionnaireTookId
        )
        .then((res) => {
          
          if(res != undefined) {
            this.frequency = res.data[0].reminder_frequency;
            this.questionnairesAttempts = res.data[0].attempts;

            this.subcategory = res.data[0].questionnaire.subcategory.name;

            this.category = res.data[0].questionnaire.subcategory.questionnairecategory.name;
            
            for(let i = 0; i < res.data[0].attempts.length; i++) {
              res.data[0].attempts[i].created_at = moment(res.data[0].attempts[i].created_at, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY');          
            }
            this.tableShowOverlay = false;
          }
        })
        .catch((error) => {
          console.log(error);
          
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Something went wrong. Please try again",
              icon: "AlertCircleIcon",
              variant: "danger",
            },
          });
        });
    },

    getLatestReminders() {

      this.$http.get('/api/auth/user/reminders?questionnaire_took_id=' + this.questionnaireTookId)
      .then((res) => {
        if(res != undefined) {
          
          // Fill Reminders
          for (let i = 0; i < res.data.latest_reminders.length; i++) {
            let number_of_days_to_add = 0;
            if (res.data.latest_reminders[i].reminder_frequency == "weekly") {
              number_of_days_to_add = 7;
            } else if (
              res.data.latest_reminders[i].reminder_frequency == "monthly"
            ) {
              number_of_days_to_add = 30;
            } else if (
              res.data.latest_reminders[i].reminder_frequency == "quarterly"
            ) {
              number_of_days_to_add = 90;
            } else {
              number_of_days_to_add = 0;
            }

            res.data.latest_reminders[i].created_at = moment(
              res.data.latest_reminders[i].created_at,
              "YYYY-MM-DD HH:mm:ss"
            )
              .add(number_of_days_to_add, "days")
              .format("ddd, MMM DD, YYYY");
          }

          res.data.latest_reminders.sort(function compare(a, b) {
            var dateA = new Date(a.created_at);
            var dateB = new Date(b.created_at);
            return dateA - dateB;
          });

          this.data.reminders.reminderData = res.data.latest_reminders;

        }
      })
      .catch((error) => {
        console.log(error)
        
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Something went wrong. Please try again",
            icon: "AlertCircleIcon",
            variant: "danger",
          },
        });
      })
    },
    getAllData() {
      store.commit("formStoreModule/setShow", true);
      this.getData()
      this.getSubmissionsData();
      this.getLatestReminders()
    },

  },
  
  mounted() {
    this.questionnaireId = this.$route.params.questionnaireId;
    
    this.$http.get('/api/auth/questionnaire/took/' + this.$route.params.id)
      .then((res) => {          
        this.questionnaireId = res.data.id;
        this.questionnaireCategory = res.data.subcategory.questionnairecategory.name;
        this.getAllData();
    });

    EventBus.$on('getFreshAnalytics', (data) => {
      this.getData(data.from, data.to)
    })
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/dashboard-ecommerce.scss";
@import "@core/scss/vue/libs/chart-apex.scss";

.fix-height-for-cols {
  margin-bottom: 2rem!important;
  height: 100%!important;
}

.fix-height-for-cols .card {
  height: 100%;
}
</style>
