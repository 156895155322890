<template>
    <div>
        <b-media class="d-flex align-items-center pt-0 px-0">
            <template #aside>
                <b-avatar :src="makeAvatar(username)" />
            </template>
        
            <div class="user-response">
                <div>
                    <h6 class="m-0">{{ username }}</h6>
                </div>
                <div>
                    <follow-button :data="data" />
                </div>
            </div>
        </b-media>

        <div class="row no-gutters">
            <div class="col-7 px-0">
                <h6 class="cursor-pointer" @click="openFinishedTraitModal(id)">{{ title }}</h6>
            </div>
            <div class="col-5 px-0 text-right">
                <small class="text-muted">{{ date | prepareDate }}</small>
            </div>
        </div>
        <div class="d-flex justify-content-between">
            <div>
                <small>Latest score - {{ score }}/10</small>
            </div>
            <div>
                <reactions :data="data" withSpaces="true" modalFlag="true" />
            </div>
        </div>
        
    </div>
</template>

<script>
import EventBus from '../../main.js';
import {
  BAvatar, BMedia, BButton, BCollapse, VBToggle, BListGroup, BListGroupItem, BAvatarGroup, BBadge, VBTooltip,
} from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import FollowButton from '@/components/users/FollowButton.vue'
import Reactions from '@/components/users/Reactions.vue'
import UsersSameTraitSingle from '@/components/my-hub/UsersSameTraitSingle.vue'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
    components: {
        AppTimeline,
        AppTimelineItem,
        BMedia,
        BAvatar,
        BButton,
        BCollapse,
        BListGroup,
        BListGroupItem,
        BAvatarGroup,
        BBadge,
        FollowButton,
        Reactions,
        UsersSameTraitSingle
    },
    directives: { 'b-toggle': VBToggle, 'b-tooltip': VBTooltip, Ripple },
    props: {
        trait: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            data: {},
            id: "",
            title: "",
            date: "",
            score: "",
            username: "",
        }
    },
    methods: {
        openFinishedTraitModal(id) {
            EventBus.$emit('openFinishedTraitSupportModal', id);
        },
    },
    mounted() {
        this.data = this.trait
        this.id = this.trait.id
        this.title = this.trait.userquestionnairetook.questionnaire.title
        this.date = this.trait.created_at
        this.score = this.trait['Questionnaire Score'].section_score
        this.username = this.trait.userquestionnairetook.user.username

        // Get fresh User Same Trait Single data
        EventBus.$on('callFreshUserSameTraitSingleData' + this.trait.id, () => {
            this.$http.get("/api/auth/questionnaire/results/related/single/" + this.trait.id)
            .then((res) => {
                if(res != undefined) {
                    this.data = res.data
                }
            })
            .catch((err) => {
                console.log(err)
                // Show tast
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Something went wrong. Please try again",
                        icon: "AlertCircleIcon",
                        variant: "danger",
                    },
                });
            })
        });
    }
}
</script>

<style>
    .user-response {
        display: flex; 
        align-items: center;
        justify-content: space-between;
    }

    @media (min-width:1200px) and (max-width:1390px) {
        .user-response {
            display: block!important;
        }
    }
</style>