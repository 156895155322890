import { render, staticRenderFns } from "./FutureReminders.vue?vue&type=template&id=531638d8&scoped=true&"
import script from "./FutureReminders.vue?vue&type=script&lang=js&"
export * from "./FutureReminders.vue?vue&type=script&lang=js&"
import style0 from "./FutureReminders.vue?vue&type=style&index=0&id=531638d8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "531638d8",
  null
  
)

export default component.exports