<template>
    <b-card no-body>
        <b-card-header class="pb-1">
                Select period
            <b-card-title class="mb-0 w-100 line-chart-title-date">
            </b-card-title>
        </b-card-header>

        <b-card-body>
            <div class="range-datepicker-width">
                <flat-pickr
                v-model="rangeDate"
                class="form-control"
                :config="options"
                :placeholder="from + ' to ' + to"
                />
            </div>
        </b-card-body>
    </b-card>
</template>

<script>
import { BCard, BCardHeader, BCardBody, BCardTitle } from "bootstrap-vue";
import flatPickr from 'vue-flatpickr-component'
import EventBus from '@/main.js';


export default {
    components: {
        BCard,
        BCardHeader,
        BCardBody,
        BCardTitle,
        flatPickr
    },
    props: ['from', 'to'],
    data() {
        return {
            rangeDate: null,
            options: {
                mode: 'range',
                onChange: function(rawdate, altdate, FPOBJ) {
                    if(altdate.includes(" to ")) {
                        let range = altdate.split(' to ')
                        let from = range[0]
                        let to = range[1]

                        // Emit event to get fresh analytics data from Home.vue
                        EventBus.$emit('getFreshAnalytics', { from: from, to: to })

                        FPOBJ.close(); // Close datepicker on date select
                        FPOBJ._input.blur(); // Blur input field on date select
                    }
                }
            },
            from_date: "",
            to_date: ""
        }
    },
}
</script>

<style scoped>
    .range-datepicker-width {
        max-width: 100%;
        width: 310px;
    }
</style>